import { createRouter, createWebHistory } from 'vue-router';
import MessageList from './components/MessageList.vue';

const routes = [
    {
        path: '/u/:hash',
        name: 'MessageList',
        component: MessageList,
        props: true,
    },
    // другие маршруты...
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;